<template>
   <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%">
       <div class="box">
           <iframe style="width: 100%;height: 100%;"
                   :src="`https://data-view.niubeiapp.com/?type=`+(t+1)+'&name='+titles[t]"
                   frameborder="0"></iframe>
       </div>
   </div>
</template>

<script>
export default {
	name: "dataView",
	props: {
		t: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			titles: ['陕驿数据中心', '中小企业数据中心', '海外仓数据中心']
		}
	}
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100%;
    max-width: 2000px;
    max-height: 1100px;
    margin: 0 auto;
    overflow: hidden;

}
</style>
