<template>
    <DataView :t="1"/>
</template>

<script>
import DataView from "@/views/data-report/dataView";

export default {
	name: "dataTotal",
	components: {
		DataView
	}
}

</script>

<style scoped>

</style>
